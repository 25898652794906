<template>
  <div class="zem-table">
    <div class="zem-table__changed-columns">
      <img
        :src="require('@/assets/icons/horizontal-menu.svg')"
        alt=""
        @click="showColumnsMenu = !showColumnsMenu"
      />
      <div
        v-if="showColumnsMenu"
        v-click-outside="vcoConfig"
        class="zem-table__changed-columns__menu-container"
      >
        <h6 class="zem-table__changed-columns__title">Показывать колонки</h6>
        <div class="zem-table__changed-columns__menu">
          <draggable
            v-model="columns"
            group="columns"
            handle=".handle"
            @change="updateSettings"
          >
            <div
              v-for="(column, index) in columns"
              :key="index"
              class="zem-table__changed-columns__item"
            >
              <ZemCheckboxGroup
                v-model="column.showInTable"
                :inputValue="column.label"
                @change="updateSettings"
              >
                {{ column.label }}
              </ZemCheckboxGroup>
              <img
                :src="require('@/assets/icons/move-vertical.svg')"
                alt=""
                class="handle"
              />
            </div>
          </draggable>
        </div>
      </div>
    </div>

    <div class="zem-table__container" ref="container">
      <table class="zem-table__main" ref="table">
        <thead>
          <tr>
            <!-- Чекбокс глобального выделения строк таблицы  -->
            <th
              class="checkbox cursor-pointer"
              @click.prevent="onClickAllCheckboxes"
            >
              <ZemCheckbox :value="selectedAll"></ZemCheckbox>
            </th>

            <!-- Формирование заголовков столбцов и сортировка -->
            <template v-for="(column, index) in columns">
              <th
                v-if="column.showInTable && column.sortField !== null"
                :key="index"
                :ref="'th-' + index"
                :class="{
                  sortable: column.field !== 'checkbox',
                  'cursor-pointer': column.field !== 'checkbox',
                }"
              >
                <div
                  class="tw-flex tw-text-center tw-justify-start max-md:tw-text-xs"
                  @click="
                    sortingFields.includes(column.sortField)
                      ? onSortable(index, column)
                      : null
                  "
                >
                  <span>{{ column.label }}</span>
                  <div
                    v-if="sortingFields.includes(column.sortField)"
                    class="btn-sort"
                  ></div>
                </div>
              </th>
              <th
                v-else-if="column.showInTable && column.sortField === null"
                :key="index"
                :ref="'th-' + index"
              >
                <div>
                  <span>{{ column.label }}</span>
                </div>
              </th>
            </template>
          </tr>
        </thead>

        <tbody>
          <template v-for="(row, indexRow) in rows">
            <tr
              :key="indexRow"
              :class="{ 'select-row': selectedItems.includes(row.id) }"
            >
              <td
                class="table-checkbox"
                @click.prevent="onClickCheckbox(row, indexRow)"
              >
                <ZemCheckbox
                  :value="selectedItems.includes(row.id)"
                ></ZemCheckbox>
              </td>
              <template v-for="(column, indexCol) in columns">
                <td
                  v-if="column.showInTable"
                  :key="indexCol"
                  :class="{
                    'tw-p-0 tw-px-2':
                      column.sortField === 'order_pipeline_statuses.name',
                  }"
                  class="max-md:tw-text-sm"
                  @click.prevent.stop="onClickEvent(column, row, indexRow)"
                >
                  <!-- Type date-->
                  <span
                    v-if="column.type === 'date'"
                    v-tippy="{ delay: [1000, 0], touch: false }"
                    :content="formatDate(row[column.field])"
                  >
                    {{ formatDate(row[column.field]) }}
                  </span>

                  <!-- Type datetime -->

                  <span
                    v-else-if="
                      [
                        'be_confirmed',
                        'be_closed',
                        'consider_other',
                        'multi_day',
                      ].includes(column.field)
                    "
                    v-tippy="{ delay: [1000, 0], touch: false }"
                    :content="formatDateTime(row[column.field])"
                  >
                    <ZemCheckbox
                      :value="row[column.field]"
                      disabled
                    ></ZemCheckbox>
                  </span>

                  <!-- Type datetime -->

                  <span
                    v-else-if="column.type === 'datetime'"
                    v-tippy="{ delay: [1000, 0], touch: false }"
                    :content="formatDateTime(row[column.field])"
                  >
                    {{ formatDateTime(row[column.field]) }}
                  </span>

                  <!-- Type link -->

                  <span
                    v-else-if="column.type === 'custom'"
                    v-tippy="{ delay: 500, touch: false }"
                    :content="parse(column, row)"
                  >
                    <slot :name="column.slotName"></slot>
                  </span>

                  <!-- Type list objects -->

                  <span
                    v-else-if="column.type === 'list-objects'"
                    v-tippy="{ delay: [1000, 0], touch: false }"
                    :content="
                      row[column.field].data
                        .map((el) => {
                          return el.title;
                        })
                        .join(', ')
                    "
                  >
                    {{
                      row[column.field].data
                        .map((el) => {
                          return el.title;
                        })
                        .join(", ")
                    }}
                  </span>

                  <!-- Field brand -->

                  <span
                    v-else-if="column.field === 'brand'"
                    v-tippy="{ delay: [1000, 0], touch: false }"
                    :content="row[column.field].title"
                  >
                    {{ row[column.field].title }}
                  </span>

                  <span
                    v-else-if="['region', 'country'].includes(column.field)"
                    v-tippy="{ delay: [1000, 0], touch: false }"
                    :content="row[column.field].data.name"
                  >
                    {{ row[column.field].data.name }}
                  </span>

                  <!-- Field phone -->

                  <span
                    v-else-if="column.field === 'phone'"
                    v-tippy="{ delay: [1000, 0], touch: false }"
                    :content="formattedPhone(row.phone)"
                  >
                    {{ formattedPhone(row.phone) }}
                  </span>

                  <!-- Field human.data.fio -->

                  <span
                    v-else-if="column.field === 'human.data.fio'"
                    v-tippy="{ delay: [1000, 0], touch: false }"
                    :content="
                      !row.organization_id
                        ? row.human.data.fio
                        : row.organization.data.title
                    "
                  >
                    {{
                      !row.organization_id
                        ? row.human.data.fio
                        : row.organization.data.title
                    }}
                  </span>

                  <!-- Label Взято -->

                  <span
                    v-else-if="column.label === 'Взято'"
                    v-tippy="{ delay: [1000, 0], touch: false }"
                    :content="
                      !row['organization_winner_id']
                        ? row['winnerBy'].data.fio
                        : row['organization_winner'].data.title
                    "
                  >
                    {{
                      !row["organization_winner_id"]
                        ? row["winnerBy"].data.fio
                        : row["organization_winner"].data.title
                    }}
                  </span>

                  <!-- SortField client_phone -->

                  <span
                    v-else-if="column.sortField === 'client_phone'"
                    v-tippy="{ delay: [1000, 0], touch: false }"
                    :content="viewPhone(row)"
                  >
                    {{ viewPhone(row) }}
                  </span>

                  <!-- SortField client_email -->

                  <span
                    v-else-if="column.sortField === 'client_email'"
                    v-tippy="{ delay: 500, touch: false }"
                    :content="viewEmail(row)"
                  >
                    {{ viewEmail(row) }}
                  </span>

                  <!-- Field roles -->

                  <span
                    v-else-if="column.field === 'roles'"
                    v-tippy="{ delay: 500, touch: false }"
                    :content="
                      row.roles.data.map((role) => role.display_name).join(', ')
                    "
                  >
                    {{
                      row.roles.data.map((role) => role.display_name).join(", ")
                    }}
                  </span>

                  <!-- Field pdf export -->

                  <span v-else-if="column.field === 'pdf_export'">
                    <zem-link
                      v-if="row['pdf_export'].d !== null"
                      style="display: inline-block;"
                      @click.native.stop="onClickLink(row['pdf_export'].d)"
                      >D</zem-link
                    >
                    <span v-else>D</span>
                    <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
                    <zem-link
                      v-if="row['pdf_export'].r !== null"
                      style="display: inline-block;"
                      @click.native.stop="onClickLink(row['pdf_export'].r)"
                      >R</zem-link
                    >
                    <span v-else>R</span>
                  </span>

                  <!-- Field estimate_title -->

                  <span
                    v-else-if="column.type === 'estimate_title'"
                    v-tippy="{ delay: 500, touch: false }"
                    :content="row[column.field]"
                  >
                    <zem-link
                      v-if="row['google_drive_file_url'] !== null"
                      style="display: inline-block;"
                      @click.native.stop="
                        onClickLink(row['google_drive_file_url'])
                      "
                      >{{ row[column.field] }}</zem-link
                    >
                    <span v-else>{{ row[column.field] }}</span>
                  </span>

                  <!-- Field amocrm_lead_id -->

                  <span
                    v-else-if="column.type === 'amocrm_lead_id'"
                    v-tippy="{ delay: 500, touch: false }"
                    :content="row[column.field]"
                  >
                    <zem-link
                      v-if="row['amocrm_lead_id'] !== null"
                      style="display: inline-block;"
                      @click.native.stop="onClickLink(row['amocrm_lead_url'])"
                      >{{ row[column.field] }}</zem-link
                    >
                    <span v-else>{{ row[column.field] }}</span>
                  </span>

                  <!-- Field estimate_status -->

                  <span
                    v-else-if="
                      ['estimate_status', 'event_status'].includes(column.type)
                    "
                    v-tippy="{ delay: 500, touch: false }"
                    :content="row[column.nameField].data.name"
                  >
                    <span
                      v-if="row[column.nameField].data.name"
                      :class="`color-dot tw-bg-[${
                        row[column.nameField].data.params.color
                      }]`"
                    >
                    </span>
                    {{ row[column.nameField].data.name }}
                  </span>

                  <!-- Field confirmation_status -->

                  <span
                    v-else-if="['confirmation_status'].includes(column.type)"
                    v-tippy="{ delay: 500, touch: false }"
                    :content="row[column.nameField].title"
                  >
                    <span
                      v-if="row[column.nameField].title"
                      :class="`color-dot tw-bg-[${
                        row[column.nameField].name === 'need_confirm'
                          ? '#F44336'
                          : row[column.nameField].name === 'confirmed'
                          ? '#4CAF50'
                          : '#fff'
                      }]`"
                    >
                    </span>
                    {{ row[column.nameField].title }}
                  </span>

                  <!-- Field priority_organization -->

                  <span
                    v-else-if="column.field === 'priority_organization'"
                    v-tippy="{ delay: [1000, 0], touch: false }"
                    :content="
                      row.priority_organization === undefined || isMobile
                        ? ''
                        : row.priority_organization.data.title
                    "
                  >
                    {{
                      row.priority_organization === undefined
                        ? ""
                        : row.priority_organization.data.title
                    }}
                  </span>

                  <!-- Field fio_contacts -->

                  <span
                    v-else-if="column.field === 'fio_contacts'"
                    class="tw-flex tw-justify-between tw-pr-4"
                  >
                    <span>{{ row.fio }}</span>
                    <users-icon
                      v-if="row.user_id"
                      v-tippy="{ delay: [1000, 0], touch: false }"
                      content="Контакт привязан к пользователю"
                      class="tw-min-w-5"
                      size="14"
                    ></users-icon>
                  </span>

                  <ZemTableRowProgress
                    v-else-if="
                      column.sortField === 'order_pipeline_statuses.name'
                    "
                    :column="column"
                    :row="row"
                  />

                  <!-- Other -->

                  <span
                    v-else
                    v-tippy="{ delay: [1000, 0], touch: false }"
                    :content="parse(column, row)"
                  >
                    {{ parse(column, row) }}
                  </span>
                </td>
              </template>
            </tr>
          </template>
        </tbody>
      </table>
      <div
        class="zem-table__scroll-sidebar-left"
        v-show="scrollLeftOffset > 2 && !isLoading"
        @mouseover="onMouseOverLeft"
        @mouseleave="onMouseLeave"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="51"
          height="97"
          viewBox="0 0 51 97"
          fill="none"
        >
          <path
            opacity="0.5"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.5 97C29.2858 97 51 75.2858 51 48.5C51 21.7142 29.2858 0 2.5 0C1.66153 0 0.828023 0.0212771 0 0.0633208V96.9367C0.828023 96.9787 1.66153 97 2.5 97Z"
            fill="#0DB2B2"
            fill-opacity="0.5"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M26.2811 62.3063C27.2396 61.3814 27.2396 59.8818 26.2811 58.9569L14.9258 48L26.2811 37.0431C27.2396 36.1182 27.2396 34.6186 26.2811 33.6937C25.3225 32.7688 23.7684 32.7688 22.8098 33.6937L9.71892 46.3253C8.76036 47.2502 8.76036 48.7498 9.71892 49.6747L22.8098 62.3063C23.7684 63.2312 25.3225 63.2312 26.2811 62.3063Z"
            fill="#FEFEFF"
          />
        </svg>
      </div>
      <div
        class="zem-table__scroll-sidebar-right"
        v-show="scrollRightOffset > 2 && !isLoading"
        @mouseover="onMouseOverRight"
        @mouseleave="onMouseLeave"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="51"
          height="97"
          viewBox="0 0 51 97"
          fill="none"
        >
          <path
            opacity="0.5"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M48.5 97C21.7142 97 0 75.2858 0 48.5C0 21.7142 21.7142 0 48.5 0C49.3385 0 50.172 0.0212771 51 0.0633208V96.9367C50.172 96.9787 49.3385 97 48.5 97Z"
            fill="#0DB2B2"
            fill-opacity="0.5"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24.7189 62.3063C23.7604 61.3814 23.7604 59.8818 24.7189 58.9569L36.0742 48L24.7189 37.0431C23.7604 36.1182 23.7604 34.6186 24.7189 33.6937C25.6775 32.7688 27.2316 32.7688 28.1902 33.6937L41.2811 46.3253C42.2396 47.2502 42.2396 48.7498 41.2811 49.6747L28.1902 62.3063C27.2316 63.2312 25.6775 63.2312 24.7189 62.3063Z"
            fill="#FEFEFF"
          />
        </svg>
      </div>
    </div>

    <div
      v-if="!loadMore['enable']"
      class="zem-table__pagination max-lg:tw-hidden"
    >
      <div
        v-if="lowerZone"
        class="zem-table__pagination__number hover:tw-text-[#0db2b2]"
        @click="onPageSelected(1)"
      >
        1
      </div>
      <div
        v-if="lowerZone"
        class="zem-table__pagination__number hover:tw-text-[#0db2b2]"
        @click="onPageScroll('-')"
      >
        ...
      </div>
      <div
        v-for="(page, idx) in pageSelection"
        :key="idx"
        :class="{
          'zem-table__pagination__number--active': page === currentPage,
        }"
        class="zem-table__pagination__number hover:tw-text-[#0db2b2]"
        @click="onPageSelected(page)"
      >
        {{ page }}
      </div>
      <div
        v-if="upperZone"
        class="zem-table__pagination__number hover:tw-text-[#0db2b2]"
        @click="onPageScroll('+')"
      >
        ...
      </div>
      <div
        v-if="upperZone"
        class="zem-table__pagination__number hover:tw-text-[#0db2b2]"
        @click="onPageSelected(pageQuantity)"
      >
        {{ pageQuantity }}
      </div>
    </div>

    <div
      v-else-if="loadMore['showButton']"
      class="d-flex justify-content-center mt-3 mb-3"
    >
      <ZemButton
        :disabled="loadMore['loading']"
        :loading="loadMore['loading']"
        icon
        @click="$emit('next-page')"
      >
        <span class="mobile-d-none ml-1">Загрузить еще</span>
      </ZemButton>
    </div>

    <div ref="loadMore"></div>
  </div>
</template>

<script>
import ZemCheckbox from "../ui/Checkbox.vue";
import moment from "moment";
import draggable from "vuedraggable";
import ZemCheckboxGroup from "@/components/ui/ZemCheckboxGroup";
import OtherService from "@/services/other.service";
import ZemLink from "@/components/ui/ZemLink.vue";
import ZemButton from "@/components/ui/ZemButton.vue";
import { formattedPhone } from "@/assets/scripts/scripts";
import ZemTableRowProgress from "@/components/ui/ZemTableRowProgress.vue";
import { UsersIcon } from "vue-feather-icons";
import iChevronLeft from "@/components/icons/iChevronLeft.vue";

export default {
  components: {
    ZemTableRowProgress,
    ZemButton,
    ZemLink,
    ZemCheckbox,
    ZemCheckboxGroup,
    draggable,
    UsersIcon,
    iChevronLeft,
  },

  props: {
    columns: {
      type: Array,
      default: [],
    },
    sortingFields: {
      type: Array,
      default: [],
    },
    rows: {
      type: Array,
      default: [],
    },
    storeName: {
      type: String,
      default: "",
    },
    pagination: {
      type: Object,
      default: () => ({}),
    },
    loadMore: {
      type: Object,
      default: () => {},
    },
    settings: {
      type: Object,
      default: () => ({
        step: 10,
        quantity: 30,
        limit: 10,
      }),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedOptions: [],
      currentColumns: [],
      checkboxes: [],
      sort: [],
      showColumnsMenu: false,
      vcoConfig: {
        handler: this.onClickOutside,
        events: ["dblclick", "click"],
        isActive: true,
        detectIFrame: true,
        capture: false,
      },
      scrolling: null,
      scrollLeftOffset: 0,
      scrollRightOffset: 0,
    };
  },

  mounted() {
    const { id } = this.$route.params;
    this.columnsSort();
    if (!id) this.$store.commit(`${this.storeName}/itemsForDelete`, []);
    this.updateColumns();

    this.initIntersectionObserver();

    this.$refs.container.addEventListener("scroll", () => {
      this.scrollLeftOffset = this.$refs.container
        ? this.$refs.container.scrollLeft
        : 0;
      if (this.scrollLeftOffset < 2) this.onMouseLeave();
      this.scrollRightOffset =
        this.$refs.container && this.$refs.table
          ? this.$refs.table.clientWidth -
            this.$refs.container.clientWidth -
            this.$refs.container.scrollLeft
          : 0;
    });
  },

  computed: {
    isMobile() {
      return this.$store.state.other.windowWidth < 1024;
    },
    selectedItems: {
      get() {
        return this.$store.state[this.storeName].selectedItemsForDelete;
      },
      set(value) {
        this.$store.commit(`${this.storeName}/itemsForDelete`, value);
      },
    },
    selectedAll() {
      if (this.rows.length === 0) {
        return false;
      } else {
        return (
          this.rows.length ===
          this.$store.state[this.storeName].selectedItemsForDelete.length
        );
      }
    },
    pageStep() {
      return this.settings.step;
    },
    pageQuantity() {
      return this.pagination.pagination.total_pages;
    },
    currentPage() {
      return this.pagination.pagination.current_page;
    },
    pageSelection() {
      if (this.rows.length === 0) {
        return [];
      } else if (this.currentPage === 1) {
        let data = [];
        if (this.pageQuantity < 3) {
          for (let i = 0; i < this.pageQuantity; i++) {
            data.push(i + 1);
          }
          return data;
        }
        return [1, 2, 3];
      } else if (this.pageQuantity === 2) {
        return [this.pageQuantity - 1, this.pageQuantity];
      } else if (this.pageQuantity === 3) {
        return [
          this.pageQuantity - 2,
          this.pageQuantity - 1,
          this.pageQuantity,
        ];
      } else if (this.currentPage > this.pageQuantity - 2) {
        return [
          this.pageQuantity - 2,
          this.pageQuantity - 1,
          this.pageQuantity,
        ];
      } else {
        return [this.currentPage - 1, this.currentPage, this.currentPage + 1];
      }
    },
    lowerZone() {
      return this.currentPage > 2 && this.pageQuantity > 3;
    },
    upperZone() {
      return this.currentPage + 1 < this.pageQuantity && this.pageQuantity > 3;
    },
    rightSidebar() {
      return this.$store.state.sidebars.rightSidebar;
    },
    settingsStore() {
      return this.$store.state[this.storeName][`${this.storeName}Pagination`]
        .custom.settings;
    },
  },

  watch: {
    rows() {
      this.formationCheckboxes();
    },
    rightSidebar() {
      if (!this.rightSidebar) {
        this.checkboxes.forEach((item, i) => {
          this.checkboxes[i] = false;
        });
        this.selectedItems = [];
      }
    },
    isLoading() {
      this.scrollRightOffset =
        this.$refs.table.clientWidth -
        this.$refs.container.clientWidth -
        this.$refs.container.scrollLeft;
    },
  },

  methods: {
    onMouseOverRight() {
      if (this.scrolling) return;
      this.scrolling = setInterval(() => {
        this.$refs.container.scrollBy({
          top: 0,
          left: 5,
        });
      }, 2);
    },
    onMouseOverLeft() {
      if (this.scrolling) return;
      this.scrolling = setInterval(() => {
        this.$refs.container.scrollBy({
          top: 0,
          left: -5,
        });
      }, 2);
    },
    onMouseLeave() {
      if (this.scrolling) {
        clearInterval(this.scrolling);
        this.scrolling = null;
      }
    },
    initIntersectionObserver() {
      const target = this.$refs.loadMore;

      if (!this.isMobile) {
        return;
      }

      if ("IntersectionObserver" in window) {
        const observer = new IntersectionObserver((entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.onLoadMore();
              // Если нужно, можно прекратить наблюдение после выполнения действия
              // observer.unobserve(target);
            }
          });
        });

        observer.observe(target);
      } else {
        window.addEventListener("scroll", this.checkVisibilityFallback);
      }
    },
    checkVisibilityFallback() {
      const rect = this.$refs.loadMore.getBoundingClientRect();
      const inView = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (inView) {
        this.onLoadMore();
        window.removeEventListener("scroll", this.checkVisibilityFallback);
      }
    },
    onLoadMore() {
      this.$emit("next-page");
    },
    formattedPhone,
    formationCheckboxes() {
      this.checkboxes = [];
      this.rows.forEach((el) => {
        this.checkboxes.push(this.selectedItems.includes(el.id));
      });
    },
    updateColumns() {
      const { orderBy, sortedBy } = this.$store.state[this.storeName];
      if (orderBy && sortedBy) {
        const orderIndex = this.columns.findIndex((el) => el.field === orderBy);
        if (orderIndex !== -1)
          this.$refs["th-" + orderIndex][0].classList.add(`sort-${sortedBy}`);
      }
    },
    columnsSort(first = false) {
      this.sort = [];
      this.columns.forEach((item, i) => {
        first && i === 0 ? this.sort.push(2) : this.sort.push(0);
        if (item.sort > 0) {
          this.$store.commit(`${this.storeName}/ordering`, item.sortField);
          this.$store.commit(`${this.storeName}/sorting`, "asc");
          this.onSortable(i, item);
        }
      });
    },
    parse(column, row) {
      const arr = column.field.split(".");
      if (row[arr[0]] === null) return "";
      if (arr.includes("data[0]")) {
        const index = arr.indexOf("data[0]");
        if (index === 1) return row[arr[0]].data[0][arr[2]];
        if (index === 2) return row[arr[0]][arr[1]].data[0][arr[3]];
        if (index === 3) return row[arr[0]][arr[1]][arr[2]].data[0][arr[4]];
        if (index === 4)
          return row[arr[0]][arr[1]][arr[2]][arr[3]].data[0][arr[5]];
        if (index === 5)
          return row[arr[0]][arr[1]][arr[2]][arr[3]][arr[4]].data[0][arr[6]];
      } else {
        if (arr.length === 1) return row[arr[0]];
        if (arr.length === 2) return row[arr[0]][arr[1]];
        if (arr.length === 3) return row[arr[0]][arr[1]][arr[2]];
        if (arr.length === 4) return row[arr[0]][arr[1]][arr[2]][arr[3]];
        if (arr.length === 5)
          return row[arr[0]][arr[1]][arr[2]][arr[3]][arr[4]];
        if (arr.length === 6)
          return row[arr[0]][arr[1]][arr[2]][arr[3]][arr[4]][arr[5]];
      }
    },
    onClickEvent(column, row, indexRow) {
      this.$emit("on-cell-click", {
        rowIndex: indexRow,
        column: column,
        row: row,
      });
      this.changeCheckbox(true, row, indexRow);
    },
    onClickLink(link) {
      window.open(link, "_blank");
    },
    onClickCheckbox(row, indexRow) {
      this.$emit("on-cell-click", {
        rowIndex: indexRow,
        row: row,
        column: {
          field: "checkbox",
        },
      });
      this.changeCheckbox(false, row, indexRow);
    },
    changeCheckbox(event, row, indexRow) {
      if (event) {
        this.checkboxes.forEach((item, i) => {
          this.checkboxes[i] = false;
        });
        this.checkboxes[indexRow] = true;
        this.selectedItems = [];
        this.selectedItems.push(row.id);
      } else {
        this.checkboxes[indexRow] = !this.checkboxes[indexRow];
        if (this.checkboxes[indexRow]) {
          this.selectedItems.push(row.id);
        } else {
          this.selectedItems.splice(
            this.selectedItems.findIndex((i) => i === row.id, 1),
            1
          );
        }
      }

      if (this.selectedItems.length === 0)
        this.$store.commit("sidebars/changeRightSidebar", false);
    },
    formatDate(date) {
      return date === null ? "" : moment.unix(date).format("DD.MM.YYYY");
    },
    formatDateTime(date) {
      return date === null ? "" : moment.unix(date).format("DD.MM.YYYY HH:mm");
    },
    onSortable(i, column) {
      const { sortedBy, orderBy } = this.$store.state[this.storeName];
      const elementClassList = this.$refs["th-" + i][0].classList;
      if (column.sortField === "order_status_id")
        column.sortField = "order_statuses|title";
      if (column.sortField === "order_partner_status_id")
        column.sortField = "order_partner_statuses|title";
      if (column.sortField === "priority_organization_id")
        column.sortField = "title";

      this.columns.forEach((e, index) => {
        if (e.showInTable) {
          this.$refs["th-" + index][0].classList.remove("sort-asc");
          this.$refs["th-" + index][0].classList.remove("sort-desc");
        }
      });

      elementClassList.remove("sort-asc");
      elementClassList.remove("sort-desc");

      if (sortedBy === null || orderBy !== column.sortField) {
        elementClassList.add("sort-asc");
        this.$store.commit(`${this.storeName}/ordering`, column.sortField);
        this.$store.commit(`${this.storeName}/sorting`, "asc");
      } else if (sortedBy === "asc") {
        elementClassList.remove("sort-asc");
        elementClassList.add("sort-desc");
        this.$store.commit(`${this.storeName}/ordering`, column.sortField);
        this.$store.commit(`${this.storeName}/sorting`, "desc");
      } else {
        elementClassList.remove("sort-desc");
        this.$store.commit(`${this.storeName}/ordering`, column.sortField);
        this.$store.commit(`${this.storeName}/sorting`, null);
      }

      this.$emit("update-table");
    },
    onPageScroll(operation) {
      let number = 1;
      switch (operation) {
        case "-":
          if (this.currentPage - this.pageStep > 0) {
            number = this.currentPage - this.pageStep;
          } else {
            number = 1;
          }
          this.onPageSelected(number);
          break;

        case "+":
          if (this.currentPage + this.pageStep < this.pageQuantity) {
            number = this.currentPage + this.pageStep;
          } else {
            number = this.pageQuantity;
          }
          this.onPageSelected(number);
          break;
      }
    },
    onPageSelected(page) {
      if (page !== this.currentPage) {
        this.$store.commit(`${this.storeName}/changePage`, page);
        this.$emit("update-table");
      }
    },
    onClickOutside() {
      this.showColumnsMenu = false;
    },
    viewPhone(row) {
      return this.formattedPhone(row.client_phone);
    },
    viewEmail(row) {
      return row.client_email;
    },
    createSettings() {
      const data = {
        key: this.settingsStore.key,
        value: {
          data: this.columns,
        },
        type: "data",
      };
      OtherService.createSettings(data);
      this.$store.commit(`${this.storeName}/changePagination`, data);
    },
    updateSettings() {
      if (this.settingsStore.value.hasOwnProperty("data")) {
        OtherService.updateSettings({
          key: this.settingsStore.key,
          value: {
            data: this.columns,
          },
          type: "data",
        });
      } else {
        this.createSettings();
      }
    },
    onClickAllCheckboxes() {
      if (this.selectedAll) {
        this.checkboxes.forEach((el, index) => {
          this.checkboxes[index] = false;
        });
        this.selectedItems = [];
      } else {
        this.checkboxes.forEach((el, index) => {
          this.checkboxes[index] = true;
        });
        this.selectedItems = this.rows.map((el) => {
          return el.id;
        });
      }
    },
  },
};
</script>

<style lang="scss">
.zem-table {
  width: 100%;
  margin-top: 20px;
  position: relative;

  &__container {
    overflow-y: auto;
    margin-bottom: 15px;
  }

  &__scroll-sidebar-right,
  &__scroll-sidebar-left {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50px;
    display: flex;
    align-items: center;
    z-index: 100;
  }

  &__scroll-sidebar-left {
    left: 0;
  }

  &__scroll-sidebar-right {
    right: 0;
  }

  &__main {
    font-size: 16px;
    border-collapse: collapse;
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    table-layout: auto;

    thead {
      width: 100%;
      height: 33px;

      tr {
        th {
          padding: 10px 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 13px;
          color: #9ba6b2;
          background: #f8fafb;
          vertical-align: middle !important;
          text-align: left;
          border-bottom: 0;
          position: relative;

          > div {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;

            span {
              white-space: nowrap;
            }
          }

          .btn-sort {
            width: 15px;
            height: 100%;
          }

          .btn-sort:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            //left: 6px;
            top: 18px;
            margin-bottom: -7px;
            border-left: 5px solid #0000;
            border-right: 5px solid #0000;
            border-top: 5px solid #606266;
          }

          .btn-sort:before {
            border-top: 5px solid #c3cbd4;
          }

          .btn-sort:after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            //left: 6px;
            bottom: 19px;
            margin-top: -7px;
            border-left: 5px solid #0000;
            border-right: 5px solid #0000;
            border-bottom: 5px solid #606266;
          }

          .btn-sort:after {
            border-bottom: 5px solid #c3cbd4;
          }
        }

        th.sort-asc {
          color: var(--accent) !important;

          .btn-sort:after {
            border-bottom: 5px solid var(--accent);
          }
        }

        th.sort-desc {
          color: var(--accent) !important;

          .btn-sort:before {
            border-top: 5px solid var(--accent);
          }
        }

        th:first-child {
          padding-left: 24px !important;
        }

        th:last-child {
          min-width: 40px;
          text-align: right;
          padding-right: 50px !important;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 10px 8px;
          border: none !important;
          font-weight: 400;
          font-size: 12px;
          line-height: 13px;
          color: #3e3e3e;
          text-align: left;
          white-space: nowrap;

          > div {
            max-width: 250px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .color-dot {
            height: 7px;
            width: 7px;
            display: inline-block;
            border-radius: 50%;
            margin-right: 5px;

            &-green {
              background-color: #2eb67d;
            }

            &-orange {
              background-color: #ecb22e;
            }

            &-grey {
              background-color: #d9d9d9;
            }

            &-red {
              background-color: #e36c6c;
            }
          }
        }

        &.select-row {
          background-color: var(--swans-down) !important;
        }
      }

      tr:nth-of-type(even) {
        background-color: #f5f8fa;
      }

      tr:hover {
        cursor: pointer;
        //background-color: #D7EBEC;

        td {
          color: var(--accent) !important;
        }
      }

      td:first-child {
        padding-left: 24px !important;
      }

      .table-checkbox {
        width: 50px;
      }
    }
  }

  &__pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: nowrap;
    align-items: flex-end;
    font-size: 15px;
    user-select: none;
    margin: 0 35px 0 0;
    padding-bottom: 20px;

    &__number {
      padding: 0 4px;
      color: var(--oslo-gray);
      border-bottom: 1px solid var(--oslo-gray);
      cursor: pointer;

      &--active {
        font-weight: bold;
        color: var(--accent);
        border-bottom: 2px solid var(--accent);
      }
    }
  }

  &__changed-columns {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    height: 35px;
    padding-right: 10px;
    background: #f8fafb;
    display: flex;
    align-items: center;

    > img {
      padding: 5px 8px;
      border: 1px solid #f8fafb;
      border-radius: 4px;
    }

    &__menu-container {
      position: absolute;
      padding: 10px;
      background: #fefeff;
      box-shadow: 0 4px 12px 6px rgb(141 141 141 / 12%);
      border-radius: 4px;
      right: 20px;
      top: 30px;
      text-align: left;
    }

    &__title {
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: #9ba6b2;
      white-space: nowrap;
      margin: 0 0 8px;
    }

    &__item {
      //display: flex;
      //align-items: center;
      display: grid;
      grid-template-columns: auto 13px;

      .zem-checkbox {
        //flex-grow: 1;
        height: 14px;
        padding-left: 21px;
        white-space: nowrap;
        padding-right: 16px;
      }

      img {
        min-width: 14px;
        //margin-left: 16px;
      }
    }

    &__item:not(:last-child) {
      padding-bottom: 8px;
    }
  }
}
</style>
