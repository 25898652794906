<template>
  <div :style="{'z-index': showDeleteModal ? 3 : 2}" class="table">
    <div class="table__container">
      <h4 class="table__title tw-pr-3 max-md:tw-text-2xl">{{ title }}</h4>
      <div class="tw-flex tw-gap-x-2 md:tw-gap-x-4">
        <zem-link
          v-if="!$store.state.sidebars.leftSidebar && isFilter"
          v-tippy="{content: $t('Filter')}"
          class="zem-link-mobile tw-gap-x-1.5"
          @click="() => $store.commit('sidebars/changeLeftSidebar', true)"
        >
          <filter-icon size="16" />
          <span v-if="isShowTitleButton" class="mobile-d-none">{{ $t('Filter') }}</span>
        </zem-link>

        <slot name="head-buttons" />

        <!-- Разархивирование и удаление -->
        <template v-if="$store.state[name].isArchive && quantitySelectedItems > 0">
          <!-- Удаление -->
          <zem-link v-if="isRemoveFromArchive" class="zem-link-mobile tw-gap-x-1.5" @click="showDeleteModal = true">
            <img :src="require('@/assets/icons/basket.svg')" alt="" />
            <span v-if="isShowTitleButton" class="mobile-d-none">{{ $t('Delete') }}</span>
          </zem-link>

          <!-- Разархивирование -->
          <zem-link
            v-if="isArchive"
            v-tippy="{content: $t('Unzip')}"
            class="tw-gap-x-1.5"
            @click="
              () => {
                $emit('return-from-archive')
              }
            "
          >
            <img :src="require('@/assets/icons/arrow-up-circle.svg')" alt="" class="zem-link-mobile" width="16" />
            <span v-if="isShowTitleButton" class="mobile-d-none">{{ $t('Unzip') }}</span>
          </zem-link>
        </template>

        <!-- Архивирование и копирование -->
        <template v-if="quantitySelectedItems > 0 && !$store.state[name].isArchive && isDelete">
          <!-- Копирование -->
          <zem-link
            v-if="
              quantitySelectedItems < 2 &&
              $store.state.sidebars.rightSidebar &&
              ['products', 'products-id', 'events', 'events-id'].includes($route.name) &&
              showAddButton
            "
            v-tippy="{content: $t('Copy')}"
            class="tw-gap-x-1.5"
            @click="
              () => {
                $emit('copy-item')
              }
            "
          >
            <img :src="require('@/assets/icons/copy.svg')" alt="" width="16" />
            <span v-if="isShowTitleButton" class="mobile-d-none">{{ $t('Copy') }}</span>
          </zem-link>

          <!-- Архивирование -->
          <zem-link
            v-if="isArchiving"
            v-tippy="{content: $t('Archive')}"
            class="tw-gap-x-1.5 max-md:tw-hidden"
            @click="
              () => {
                $emit('archive-items')
              }
            "
          >
            <i-archive class="tw-size-4 tw-fill-[#FFFFFF]" />
            <span v-if="isShowTitleButton" class="mobile-d-none">{{ $t('Archive') }}</span>
          </zem-link>
          <ZemButton
            v-if="isArchiving"
            class="md:tw-hidden"
            @click="
              () => {
                $emit('archive-items')
              }
            "
          >
            <i-archive class="tw-size-4 tw-stroke-[#FFFFFF]" />
          </ZemButton>
        </template>

        <slot name="head-buttons-center" />

        <!-- Создание -->
        <ZemButton
          v-if="title !== 'Типы позиций' && showAddButton"
          :disabled="isLoadingNewItem"
          :loading="isLoadingNewItem"
          icon
          @click="showRightSidebar"
        >
          <img
            v-if="!isLoadingNewItem"
            :class="{'tw-mb-[1px]': titleAddButton}"
            :src="require('@/assets/icons/plus.svg')"
            alt=""
          />
          <span v-if="titleAddButton">{{ titleAddButton }}</span>
        </ZemButton>

        <slot name="head-buttons-after" />
      </div>
    </div>

    <div v-if="isShowSearch || isArchive" class="table__search-block">
      <div class="table__search-block__left tw-mb-2.5 md:tw-mb-0">
        <div v-if="isShowSearch" class="table__search-block__input">
          <ZemInput
            :disabled="!isSearch"
            :placeholder="$t('message.SearchPlaceholder') + '...'"
            :value="searchQuery"
            class="m-0"
            type="text"
            @input="onSearch($event)"
          ></ZemInput>
          <img :src="require('@/assets/icons/search.svg')" alt="" class="table__search-block__input-icon" />
        </div>
      </div>
      <div class="table__search-block__right">
        <p v-if="isShowSearch" class="table__search-block__search-query max-md:tw-text-xs">
          <template v-if="pagination.pagination && pagination.pagination.total">
            {{ $t('message.Found') }} {{ pagination.pagination.total }} {{ $t('message.of') }}
            {{ pagination.custom.total }}
          </template>
        </p>
        <Checkbox
          v-if="isArchive"
          :value="$store.state[name].isArchive"
          class="checkbox-dnd ml-2 text-muted"
          @input="updateArchiveCheckbox"
        >
          Архив
        </Checkbox>
      </div>
    </div>

    <div class="table__table-block position-relative">
      <ZemTable
        ref="table"
        :columns="columnsInSettings.length > 0 ? columnsInSettings : columns"
        :load-more="loadMore"
        :pagination="pagination"
        :rows="rows"
        :sorting-fields="sortingFields"
        :store-name="name"
        :is-loading="isLoadingItems"
        @on-cell-click="onRowClick"
        @update-table="updateData"
        @next-page="$emit('next-page')"
      />

      <preloader-wrapper :show="isLoadingItems" />
    </div>

    <ZemModal
      :loading-success="loadingDelete"
      :show="showDeleteModal"
      :title="$t('Are you sure you want to remove it?') || ''"
      :title-cancel="$t('No') || ''"
      :title-success="$t('Yes') || ''"
      @on-change="showDeleteModal = !showDeleteModal"
      @on-success="
        () => {
          $emit('delete-item')
        }
      "
      @on-cancel="showDeleteModal = false"
    />
  </div>
</template>

<script>
import Search from './Search.vue'
import ZemCheckbox from './ui/Checkbox.vue'
import ZemTitle from './ui/Title.vue'
import ZemButton from './ui/ZemButton.vue'
import ZemLink from './ui/ZemLink.vue'
import ZemInput from './ui/ZemInput.vue'
import Search2 from './Search2.vue'
import moment from 'moment'
import ZemTable from '@/components/ui/ZemTable'
import ZemModal from '@/components/ui/ZemModal'
import {eventBus} from '@/main'
import Checkbox from '@/components/ui/Checkbox.vue'
import PreloaderWrapper from '@/components/PreloaderWrapper.vue'
import ZemCheckboxGroup from '@/components/ui/ZemCheckboxGroup.vue'
import {FilterIcon} from 'vue-feather-icons'
import OtherService from '@/services/other.service'
import IArchive from '@/components/icons/iArchive.vue'

export default {
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    sortingFields: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    showAddButton: {
      type: Boolean,
      default: false,
    },
    isLoadingItems: {
      type: Boolean,
      default: false,
    },
    isLoadingNewItem: {
      type: Boolean,
      default: false,
    },
    isArchive: {
      type: Boolean,
      default: true,
    },
    isSearch: {
      type: Boolean,
      default: true,
    },
    isShowSearch: {
      type: Boolean,
      default: true,
    },
    isDelete: {
      type: Boolean,
      default: true,
    },
    isUpdate: {
      type: Boolean,
      default: true,
    },
    isCreate: {
      type: Boolean,
      default: true,
    },
    isArchiving: {
      type: Boolean,
      default: true,
    },
    isRemoveFromArchive: {
      type: Boolean,
      default: true,
    },
    isShowTitleButton: {
      type: Boolean,
      default: true,
    },
    isFilter: {
      type: Boolean,
      default: true,
    },
    loadMore: {
      type: Object,
      default: () => ({
        enable: false,
        showButton: false,
        loading: false,
      }),
    },
    titleAddButton: {
      type: String,
      default: '',
    },
  },

  components: {
    IArchive,
    ZemCheckboxGroup,
    PreloaderWrapper,
    Checkbox,
    Search,
    ZemCheckbox,
    ZemTitle,
    ZemButton,
    ZemLink,
    Search2,
    ZemInput,
    ZemTable,
    ZemModal,
    FilterIcon,
  },

  data() {
    return {
      columnsInSettings: [],
      checkboxValue: true,
      selectedItemsForDelete: [],
      timer: null,
      showDeleteModal: false,
      loadingDelete: false,
    }
  },

  computed: {
    searchQuery: {
      get() {
        return this.$store.state[this.name].search
      },
      set(newValue) {
        this.$store.commit(`${this.name}/getSearch`, newValue)
      },
    },
    role() {
      return this.$store.state.user.role
    },
    quantitySelectedItems() {
      return this.$store.state[this.name].selectedItemsForDelete.length
    },
    pagination() {
      return this.$store.state[this.name][this.name + 'Pagination']
    },
    settingsStore() {
      return this.$store.state[this.name][`${this.name}Pagination`].custom.settings
    },
  },

  mounted() {
    eventBus.$on('update-table-settings', data => {
      this.checkSettingsForTable(data || [])
    })
  },

  methods: {
    updateData() {
      this.$emit('update-data')
    },
    checkSettingsForTable(data) {
      const labels1 = data.map(item => item.label)
      const labels2 = this.columns.map(item => item.label)
      const areArraysEqual =
        labels1.length === labels2.length && labels1.every((value, index) => value === labels2[index])
      let newColumns = []
      if (areArraysEqual) {
        newColumns = data
      } else {
        if (this.settingsStore && this.settingsStore.value.data)
          OtherService.updateSettings({
            key: this.settingsStore.key,
            value: {
              data: [],
            },
            type: 'data',
          })
        newColumns = this.columns
      }
      this.columnsInSettings = newColumns
    },
    updateArchiveCheckbox(e) {
      this.$store.commit(`${this.name}/changeIsArchive`, e)
      this.$store.commit(`${this.name}/itemsForDelete`, [])
      this.$store.commit('sidebars/changeRightSidebar', false)
      this.$emit('on-change-archive', e)
      this.updateData()
    },
    onRowClick(params) {
      this.$emit('on-selected', params)
    },
    onPageSelected(page) {
      if (page !== this.currentPage) {
        this.$store.commit(`${this.name}/changePage`, page)
      }
    },
    showRightSidebar() {
      this.$emit('show-right-sidebar')
      eventBus.$emit('new-element')
    },
    closeLeftSidebar() {
      this.$store.commit('sidebars/changeLeftSidebar', !this.$store.state.sidebars.leftSidebar)
    },
    changeRightSidebar() {
      this.$store.commit('sidebars/changeRightSidebar', !this.$store.state.sidebars.rightSidebar)
    },
    formatDate(date) {
      return moment.unix(date).format('DD.MM.YYYY')
    },
    onSearch(e) {
      clearTimeout(this.timer)
      this.timer = null
      this.timer = setTimeout(() => {
        this.$store.commit(`${this.name}/changePage`, 1)
        this.searchQuery = e
        this.updateData()
      }, 500)
    },
  },
  beforeDestroy() {
    eventBus.$off('update-table-settings')
  },
}
</script>

<style lang="scss" scoped>
.table {
  z-index: 2;
  flex-grow: 1;
  height: 100vh;
  background: $color-titan-white;
  box-shadow: 2px 0 8px rgba($color: $color-gray, $alpha: 0.12);
  overflow-x: auto;

  &__right-container {
    display: flex;
    align-items: center;

    .zem-link {
      margin-left: 14px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: $color-mine-shaft;
    margin: 0;
  }

  &__table-block {
    margin-top: 20px;
    //overflow-y: auto;
  }

  &__container {
    padding: 0 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__search-block {
    padding: 0 20px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      display: flex;
      align-items: center;
    }

    &__right {
      flex-grow: 1;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .zem-link {
        position: relative;

        span {
          position: absolute;
          left: 10px;
          top: -8px;
          font-size: 10px;
        }
      }

      > div:last-child {
        margin-left: 10px;
      }
    }

    &__input {
      width: 220px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      input {
        padding: 5px 24px 5px 8px;
      }

      &-icon {
        position: absolute;
        right: 9px;
        top: 8px;
        width: 10px;
      }
    }

    &__search-query {
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: $color-gull-gray;
      margin-left: 8px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .table {
    padding-bottom: 20px;
    overflow-x: unset;
    height: unset;

    &__search-block {
      flex-direction: column;
      align-items: unset;

      &__left {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 5px;
      }

      &__search-query {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .table {
    &__search-block {
      margin-top: 10px;

      &__input {
        width: 100%;

        input {
          padding: 5px 24px 5px 8px;
        }

        &-icon {
          position: absolute;
          right: 9px;
          top: 8px;
          width: 10px;
        }
      }

      &__left {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
      }

      &__search-query {
        margin-left: 0;
      }
    }
  }
}
</style>
